<template>

    <div style="width:100vw; height:100vh; position:absolute; top:0; left:0; background:rgba(57,57,57,0.4); display:flex; align-items:center; justify-content:center; z-index:10;" v-show="info.show"> 
        <div style="position:relative; padding:30px; border: 0px solid rgb(93,26,58); border-radius:10px; background: url('/img/bg-01.jpg') rgba(48,48,48,0.8); color:#FFF; min-width:25vw; max-width:75vw;">
            <div style="cursor:pointer; padding:2px 10px; position:absolute; top:0; right:0; background:#FF4136; border-radius:0 10px 0 0;" @click="info.show=false">
                x
            </div>
            <p style="font-size: 1.5rem; font-weight: 700; text-align:center;"> {{info.title}} </p>
            <p v-html="info.body"></p>
        </div>
    </div>

    <a href="https://www.esfm.ipn.mx" style="width:64px; height:64px; position:absolute; bottom:10px; right:10px; z-index:2;">
        <img src="@/assets/images/esfm.png" style="height:64px;">
    </a>

    <div ref="viewport" style="position: relative; display:flex; width: max-content; left: -100px;" id="view" @mousedown="mouseDown" draggable="false">
        <div style="position: relative">
            <img :src="img+'/photo.jpg'" :alt="title" ref="photo" draggable="false"> 
            <div v-for="p of sp" :key="'p'+p.name">
                <div class="info square" v-if="p.link==null" :style="{left: p.left+'px', top: p.top+'px', width: p.width+'px', height: p.height+'px', transform:'perspective('+p.p+'px) rotateY('+p.r+'deg)'}" @click="showinfo(p.name, p.desc)">
                    <p> {{p.name}} </p>
                </div>
                <router-link v-else :to="{name: p.link}" class="square" :style="{left: p.left+'px', top: p.top+'px', width: p.width+'px', height: p.height+'px', transform:'perspective('+p.p+'px) rotateY('+p.r+'deg)'}" style="text-center" draggable="false" :title="p.name">
                    {{p.name}}
                </router-link>
            </div>
        </div>

        <div style="position: relative">
            <img :src="img+'/photo.jpg'" :alt="title" ref="photo" draggable="false">
            <div v-for="p of sp" :key="'2p'+p.name">
                <div class="info square" v-if="p.link==null" :style="{left: p.left+'px', top: p.top+'px', width: p.width+'px', height: p.height+'px', transform:'perspective('+p.p+'px) rotateY('+p.r+'deg)'}" @click="showinfo(p.name, p.desc)">
                    <p> {{p.name}} </p>
                </div>
                <router-link v-else :to="{name: p.link}" class="square" :style="{left: p.left+'px', top: p.top+'px', width: p.width+'px', height: p.height+'px', transform:'perspective('+p.p+'px) rotateY('+p.r+'deg)'}" style="text-center" draggable="false" :title="p.name">
                    {{p.name}}
                </router-link>
            </div>
        </div>
    </div>

    <div style="width:64px; height:64px; position:absolute; bottom:10px; left:20px; z-index:2; cursor:pointer;" @click="showinfo(about.title, about.body)">
        <img src="@/assets/images/dev.png" style="height:64px;">
    </div>

</template>

<style scoped>
    .control {
        background:rgba(54,54,54,0.5);
        position:absolute;
        top:0;
        height:100vh;
        width:50px;
        display:flex;
        align-items:center;
        justify-content:center;
        z-index:2;
        cursor: pointer;
        -webkit-transition: visibility 300ms;
        transition: visibility 300ms;
    }

      .control p {
          color: #FFF;
          font-size: 2em;
      }
</style>

<script>
export default {
    name: 'Photo',
    data() {
        return {
            title: 'Tour ESFM',
            sp: [], // show points
            info: {
                show: false,
                title: '',
                body: ''
            },
            about: {
                title: 'Acerca de',
                body: 'Recorrido virtual ESFM <br> Creado por: <b> ESFM-DEV </b><br> 1 de Marzo de 2022<br>esfmdev@gmail.com'
            },
            mousedown: new MouseEvent('mousedown', {
                bubbles: false,
                cancelable: true,
                view: window,
            }),
            mouseup: new MouseEvent('mouseup', {
                bubbles: false,
                cancelable: true,
                view: window,
            }),
            touchup: new TouchEvent('touchend', {
                bubbles: false,
                cancelable: true,
                clientX: 0,
                view: window,
            }),
            touchdown: '',
        }
    },
    props: {
        img: {
            type: String,
            required: true
        },
        points: {
            type: Array,
            default: new Array()
        }
    },
    methods: {
        showinfo (title, body){
            this.info.title = title;
            this.info.body = body;
            this.info.show = true;
        },
        fstop() {
            clearInterval(this.temp);
        },
        touchStart(event) {
            this.touchdown = event;
            const view = this.$refs.viewport;
            this.posInit = {
                left: view.getBoundingClientRect().left,
                x: event.targetTouches[0].clientX,
            };
            try{
                view.addEventListener('touchmove', this.touchMove);
                view.addEventListener('touchend', this.touchEnd);
                //document.addEventListener('mouseleave', this.mouseUp);
            }
            catch {
                return 0;
            }
        },
        touchEnd (){
            this.$refs.viewport.removeEventListener('touchMove', this.touchMove);
            this.$refs.viewport.removeEventListener('touchEnd', this.touchEnd);
            //document.removeEventListener('mouseleave', this.mouseUp);
        },
        touchMove(event) {
            const dx = event.targetTouches[0].clientX - this.posInit.x;
            const view = this.$refs.viewport;
            if(dx < 0){ // ->
                if (this.posInit.left+dx <= (-1*(view.offsetWidth-window.innerWidth-50)) ){ // Si la posición mas el movimiento es menor que la longitud maxima del viewport menos el ancho de pantalla con 50 px de margen, lo movemos a la mitad del view menos esos 50 pixeles de margen
                    this.$refs.viewport.dispatchEvent(this.touchup);
                    view.style.left = -((view.offsetWidth/2)-50)+"px";//-window.innerWidth-50)+"px";
                    this.$refs.viewport.dispatchEvent(this.touchdown);
                } else {
                    view.style.left = this.posInit.left+dx+"px";
                }
            } else {
                if (this.posInit.left+dx >= -50){ // pos derecha
                    this.$refs.viewport.dispatchEvent(this.touchup);
                    view.style.left = -((view.offsetWidth/2)-this.posInit.left+dx)+"px";
                    this.$refs.viewport.dispatchEvent(this.touchdown);
                } else {
                    view.style.left = this.posInit.left+dx+"px";
                }
            }
        },

        mouseDown(event) {
            const view = this.$refs.viewport;
            this.posInit = {
                left: view.getBoundingClientRect().left,
                x: event.clientX,
            };

            try{
                view.addEventListener('mousemove', this.mouseMove);
                view.addEventListener('mouseup', this.mouseUp);
                document.addEventListener('mouseleave', this.mouseUp);
            }
            catch {
                return 0;
            }
        },
        mouseUp (){
            this.$refs.viewport.removeEventListener('mousemove', this.mouseMove);
            this.$refs.viewport.removeEventListener('mouseup', this.mouseUp);
            document.removeEventListener('mouseleave', this.mouseUp);
        },
        mouseMove(event) {
            const dx = event.clientX - this.posInit.x;
            const view = this.$refs.viewport;
            if(dx < 0){ // ->
                if (this.posInit.left+dx <= (-1*(view.offsetWidth-window.innerWidth-50)) ){ // Si la posición mas el movimiento es menor que la longitud maxima del viewport menos el ancho de pantalla con 50 px de margen, lo movemos a la mitad del view menos esos 50 pixeles de margen
                    this.$refs.viewport.dispatchEvent(this.mouseup);
                    view.style.left = -((view.offsetWidth/2)-50)+"px";//-window.innerWidth-50)+"px";
                    this.$refs.viewport.dispatchEvent(this.mousedown);
                } else {
                    view.style.left = this.posInit.left+dx+"px";
                }
            } else {
                if (this.posInit.left+dx >= -50){ // pos derecha
                    this.$refs.viewport.dispatchEvent(this.mouseup);
                    view.style.left = -((view.offsetWidth/2)-this.posInit.left+dx)+"px";
                    this.$refs.viewport.dispatchEvent(this.mousedown);
                } else {
                    view.style.left = this.posInit.left+dx+"px";
                }
            }
        }
    },
    mounted (){
        document.getElementById('view').addEventListener('touchstart', this.touchStart);
        document.getElementsByTagName('img')[1].addEventListener('load', ()=>{
            const w = document.getElementsByTagName('img')[1].width;
            const h = document.getElementsByTagName('img')[1].height;
            this.points.forEach( (point, index) => {
                this.sp[index] = {};
                this.sp[index].top = ((point.top*h)/100);
                this.sp[index].left = ((point.left*w)/100);
                this.sp[index].width = ((point.width*w)/100);
                this.sp[index].height = ((point.height*h)/100);
                this.sp[index].link = point.link;
                this.sp[index].name = point.name;
                this.sp[index].desc = (point.desc) ? point.desc : null ;
                this.sp[index].p = (point.p) ? point.p : 0;
                this.sp[index].r = (point.r) ? point.r : 0;
            });

            window.addEventListener('resize', () => {
                const w = document.getElementsByTagName('img')[1].width;
                const h = document.getElementsByTagName('img')[1].height;
                this.points.forEach( (point, index) => {
                    this.sp[index] = {};
                    this.sp[index].top = ((point.top*h)/100);
                    this.sp[index].left = ((point.left*w)/100);
                    this.sp[index].width = ((point.width*w)/100);
                    this.sp[index].height = ((point.height*h)/100);
                    this.sp[index].link = point.link;
                    this.sp[index].name = point.name;
                    this.sp[index].desc = (point.desc) ? point.desc : null ;
                    this.sp[index].p = (point.p) ? point.p : 0;
                    this.sp[index].r = (point.r) ? point.r : 0;
                });   
            });
        });
    }
}
</script>
