<template>
	<photo img="/img/e9/p3/cubicle" :points="points"/>
</template>

<script>
	import photo from '@/components/photo'

	export default {
		name: 'Prefecture',
		components: {
			photo
		},
		data (){
			return {
				points: [
                    {
						link: 'E9.P2.Direction',
						name: 'Dep Física',
						left: -7.4, //'-150px',
						top: 41.8, //'350px',
						width: 9.6, //'200px',
						height: 16.3 //'120px'
					},
                    {
						link: 'E9.P3.Direction',
						name: 'Dirección',
						left: 68.5, //'1400px',
						top: 38.3, //'300px',
						width: 6.4, //'150px',
						height: 24.4 //'220px'
					},
				]
			}
		}
	}
</script>
